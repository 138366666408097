var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('form-wizard', {
    attrs: {
      "color": "#ff9801",
      "title": null,
      "subtitle": null,
      "finish-button-text": "Simpan & Checkout Pengiriman",
      "back-button-text": "Sebelumnya",
      "next-button-text": "Selanjutnya"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Daftar Barang"
    }
  }, [_c('validation-observer', {
    ref: "formBarang"
  }, [_c('div', {
    staticClass: "w-100 w-full"
  }, [_c('header', {
    staticClass: "mb-2"
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none",
    attrs: {
      "border-variant": "primary",
      "title": "Detail",
      "bg-variant": "transparent"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.kirim.no_invoice) + " ")]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.kirim.status_ket.toUpperCase()) + " ")]), _vm.kirim.status == 1 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "danger",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.batal($event);
      }
    }
  }, [_vm._v(" Batalkan Pengiriman ")])], 1) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "8"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none",
    attrs: {
      "border-variant": "primary",
      "title": "Barang",
      "bg-variant": "transparent"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Cari Barang"
    }
  }, [_vm._v("Cari Barang")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getDataBarangs();
      }
    },
    model: {
      value: _vm.caribarang,
      callback: function callback($$v) {
        _vm.caribarang = $$v;
      },
      expression: "caribarang"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.getDataBarangs();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPageBarangs,
      "items": _vm.items,
      "fields": _vm.fieldsPenyimpananBarangs,
      "sort-by": _vm.sortByBarangs,
      "sort-desc": _vm.sortDescBarangs,
      "sort-direction": _vm.sortDirectionBarangs,
      "filter-included-fields": _vm.filterOnBarangs
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDescBarangs = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDescBarangs = $event;
      },
      "filtered": _vm.onFilteredBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(penyimpanan)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.pilihbarang,
            callback: function callback($$v) {
              _vm.pilihbarang = $$v;
            },
            expression: "pilihbarang"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : '-') + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : '-') + " ")];
      }
    }, {
      key: "cell(blok)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : '-') + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : '-') + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : '-') + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : '-') + " ")];
      }
    }, {
      key: "cell(stok)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.stok ? item.stok : '-') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.barang.satuan ? item.barang.satuan.satuan : '-') + " ")];
      }
    }], null, false, 171363586)
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none",
    attrs: {
      "border-variant": "primary",
      "title": "Gudang",
      "bg-variant": "transparent"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [this.user.level.id == 5 ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "gudang"
    }
  }, [_vm._v("Dari Gudang")]), _c('b-form-select', {
    attrs: {
      "disabled": "",
      "options": _vm.dataGudang,
      "label": "Gudang"
    },
    model: {
      value: _vm.form.from_gudang_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "from_gudang_id", $$v);
      },
      expression: "form.from_gudang_id"
    }
  })], 1) : _vm._e(), this.user.level.id != 5 ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "gudang"
    }
  }, [_vm._v("Gudang")]), _c('b-form-select', {
    attrs: {
      "options": _vm.dataGudang,
      "label": "Gudang"
    },
    model: {
      value: _vm.form.from_gudang_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "from_gudang_id", $$v);
      },
      expression: "form.from_gudang_id"
    }
  })], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [this.user.level.id == 5 ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "gudang"
    }
  }, [_vm._v("Menuju Gudang")]), _c('b-form-select', {
    attrs: {
      "options": _vm.otherGudangs,
      "label": "Gudang"
    },
    model: {
      value: _vm.form.to_gudang_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "to_gudang_id", $$v);
      },
      expression: "form.to_gudang_id"
    }
  })], 1) : _vm._e(), this.user.level.id != 5 ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "gudang"
    }
  }, [_vm._v("Menuju Gudang")]), _c('b-form-select', {
    attrs: {
      "options": _vm.otherGudangs,
      "label": "Gudang"
    },
    model: {
      value: _vm.form.to_gudang_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "to_gudang_id", $$v);
      },
      expression: "form.to_gudang_id"
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('b-button', {
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v(" Tambah ")])], 1)]), _c('footer', [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": 10,
      "current-page": 1,
      "fields": _vm.tableFields,
      "items": _vm.selectedBarangs,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [row.item.id == null ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Masukkan Barang ke Pengiriman',
            expression: "'Masukkan Barang ke Pengiriman'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "rounded": "",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.masukkanbarang(row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CheckIcon"
          }
        })], 1) : _vm._e(), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Urungkan',
            expression: "'Urungkan'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "rounded": "",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.removeBarang(row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1)];
      }
    }, {
      key: "cell(qty)",
      fn: function fn(row) {
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: row.item.qty,
            callback: function callback($$v) {
              _vm.$set(row.item, "qty", $$v);
            },
            expression: "row.item.qty"
          }
        })];
      }
    }])
  })], 1)])])], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form ",
      "size": "xl"
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelectBarangs",
      "size": "sm",
      "options": _vm.pageOptionsBarangs
    },
    model: {
      value: _vm.perPageBarangs,
      callback: function callback($$v) {
        _vm.perPageBarangs = $$v;
      },
      expression: "perPageBarangs"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelectBarangs"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "size": "sm",
      "options": _vm.sortOptionsBarangs
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortByBarangs,
      callback: function callback($$v) {
        _vm.sortByBarangs = $$v;
      },
      expression: "sortByBarangs"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortByBarangs
    },
    model: {
      value: _vm.sortDescBarangs,
      callback: function callback($$v) {
        _vm.sortDescBarangs = $$v;
      },
      expression: "sortDescBarangs"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getDataBarangs();
      }
    },
    model: {
      value: _vm.filterBarangs,
      callback: function callback($$v) {
        _vm.filterBarangs = $$v;
      },
      expression: "filterBarangs"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
        _vm.getDataBarangs();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1), _vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPageBarangs,
      "items": _vm.dataBarangs,
      "fields": _vm.fieldsBarangs,
      "sort-by": _vm.sortByBarangs,
      "sort-desc": _vm.sortDescBarangs,
      "sort-direction": _vm.sortDirectionBarangs,
      "filter-included-fields": _vm.filterOnBarangs
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDescBarangs = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDescBarangs = $event;
      },
      "filtered": _vm.onFilteredBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(show_detail)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function callback($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "row-details",
      fn: function fn(_ref9) {
        var toggleDetails = _ref9.toggleDetails,
          item = _ref9.item;
        return [_c('b-row', {
          staticClass: "mb-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('table', {
          attrs: {
            "border": "3"
          }
        }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Jenis")]), _c('th', [_vm._v("Harga")])]), _vm._l(item.harga_jual, function (harga, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(" " + _vm._s(harga.tipe ? harga.tipe.nama : "-") + " ")]), _c('td', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(harga.harga)))])]);
        })], 2)]), _c('b-col', {
          attrs: {
            "cols": "8"
          }
        }, [_c('table', {
          attrs: {
            "border": "3"
          }
        }, [_c('tr', [_c('th', [_vm._v("Satuan Dari")]), _c('th', [_vm._v("Nilai")])]), _vm._l(item.konversi, function (satuan, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v("1 " + _vm._s(satuan.satuan ? satuan.satuan.satuan : "-"))]), _c('td', [_vm._v(" " + _vm._s(_vm.formatRupiah(satuan.nilai)) + " ")])]);
        })], 2)])], 1), _c('b-button', {
          staticClass: "mb-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])];
      }
    }, {
      key: "cell(no)",
      fn: function fn(_ref10) {
        var index = _ref10.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(penyimpanan)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return undefined;
      }
    }, {
      key: "cell(harga_jual)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.harga_jual && item.harga_jual.length > 0 ? _vm.formatRupiah(item.harga_jual[0].harga) : 0) + " ")];
      }
    }, {
      key: "cell(id_satuan)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : "-") + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('section', {
          staticClass: "d-flex justify-content-center"
        }, [_vm.allowUpdate(_vm.$route.meta.module) ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": "edit-btn-".concat(item.id),
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/barang/edit/".concat(item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _c('b-tooltip', {
          attrs: {
            "target": "edit-btn-".concat(item.id)
          }
        }, [_vm._v("Edit")]), _vm.allowDelete(_vm.$route.meta.module) ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": "delete-btn-".concat(item.id),
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.deleteSingle(item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e(), _c('b-tooltip', {
          attrs: {
            "target": "delete-btn-".concat(item.id)
          }
        }, [_vm._v("Hapus")])], 1)];
      }
    }], null, false, 1266545462)
  }) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRowsBarangs,
      "per-page": _vm.perPageBarangs,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPageBarangs,
      callback: function callback($$v) {
        _vm.currentPageBarangs = $$v;
      },
      expression: "currentPageBarangs"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }